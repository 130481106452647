<template>
	<div>
		
		<div class="flex warp start xzph" v-if="$store.state.app.tenantshoppingmallconfig.goodsvivewtype==2">
			<div class="w-230 bg-f p-10 m-b-10 relative" :class="((index+1)%5)==0?'':'m-r-12'" v-for="(item,index) in goods" @click="godetail(item.id)">
				<img class="w-210 h-210" v-if="item.imageurl" :src="item.imageurl"  onerror="javascript:this.src='../../assets/img/mr.png';" alt="">
				<img class="w-210 h-210 " v-if="!item.imageurl" src="../assets/img/mr.png" alt="">
				<div class="absolute t-200 w-210">
					<div class="sell i-b c-f p-0-10 f-12 m-r-10 relative o-h w100" v-for="(cuxiao,cuxiaoindex) in item.promotesalesList.slice(0,1)" :key="cuxiaoindex">
						<img class="w-60 h-20 absolute l-10  l-0" src="../assets/img/cx2.png" alt=""> 
						<span class="relative z-i-10"><span class="bold m-r-10">{{cuxiao.salesname}}</span>{{cuxiao.promoteTitle}}</span>
					</div>
				</div>
				<div class="h-50 f-12 l-h-50 flex">
					<div class="c-ff3" v-if="item.batchInfoList&&$store.state.app.showprice!=1">
					<!-- 	<span v-if="$store.state.app.token">￥</span> 
						<span class="f-20 bold">{{$store.state.app.token?item.price:'价格登录后可见'}}</span> -->
						 <price v-if="item.batchInfoList[0]" :price="item.batchInfoList[0].price" :size="20"/>
					</div>
					<div @click.stop="addCollection(item.id,item.isfav)" class="cursor">
						<i class="iconfont c-9 relative t-2 f-16" :class="{'b2b-shoucang':item.isfav==0,'b2b-shoucang ':!item.isfav,'b2b-yishoucang c-ff8 ':item.isfav==1}"></i> 
						收藏</div>
				</div>
				
				<div class="ell c-3 f-14 m-b-15">{{item.goodsname}}</div>
				<div class="c-9 f-12 l-h-20 m-b-30">
					<p>产品规格：{{item.spec}}</p>
					<p>包装规格：{{item.pakegespce}}</p>
					
					<p class="ell">厂家：{{item.manufacturersname}}</p>
					<p>批准文号：{{item.approvalno}}</p> 
					<p>医保编码：{{item.ybcode}}</p>
				</div>  
				<div class="p-b-5 flex" @click.stop>
					 <el-input-number size="mini" :step="item.fltp?item.fltp:1" v-model="item.qty" @change="changestock(index,item.qty,0)" :min="item.fltp?item.fltp:1" :max="item.totalstock" ></el-input-number>
					 <el-button v-if="item.batchInfoList"  :disabled="(item.batchInfoList&&item.batchInfoList.length&&(item.batchInfoList[0].stock>item.batchInfoList[0].qty))?false:true" @click.stop="showselectbatch(item,index)" size="mini" :type="item.batchInfoList.length&&(item.batchInfoList[0].stock>item.batchInfoList[0].qty)?'primary':'info'" icon="b2b-gouwuche iconfont f-15"> 加入购物车</el-button>
					 <!-- <el-button  size="mini" :disabled="false" @click.stop="showselectbatch(item,index)" type="info" icon="b2b-gouwuche iconfont"> 加入购物车000</el-button> -->
				</div>
			</div>
			
			<el-dialog title="选择批号" :visible.sync="selectbatch" :close-on-click-modal="false">
			  <el-table :data="batchInfoList" border class="xzphpoup"> 
			    <el-table-column  label="批号" width="200">
					<template slot-scope="scope"> 
						<p v-if="scope.row.batchno=='defaultbno'" class="w-80">默认分配</p>
						<div v-if="scope.row.batchno!='defaultbno'">
							<!-- <span class=" border-a-ff4-1 tc c-ff4 h-20 l-h-16 p-0-5 m-r-10 m-t-5 f-12 i-b">促销</span> -->
							<span class="w-160">批号：{{scope.row.batchno}}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="groupname"label="业务组" width="130"></el-table-column>
			    <el-table-column prop="producedate"label="生产日期" width="100"></el-table-column>
			    <el-table-column prop="validdate" label="有效日期"></el-table-column>
				<el-table-column prop="stock" label="库存" v-if="showStock==1"></el-table-column>
				<el-table-column prop="price" label="金额"></el-table-column>
				<el-table-column prop="totalstock" label="数量" width="150">
					<template slot-scope="scope"> 
						<el-input-number size="mini" class="w100" :step="scope.row.fltp?scope.row.fltp:1" v-model="scope.row.qty" @change="changestock(goodskey,scope.row.qty,scope.$index)" :min="scope.row.fltp?scope.row.fltp:1" :max="1000000" label="描述文字"></el-input-number>
					</template> 
				</el-table-column>
				<el-table-column property="validdate" label="操作" width="135">
					<template slot-scope="scope"> 
						<el-button v-if="ishshowcart(scope.row.qty,scope.row.stock)"  @click="addcarorder(goodsitem,scope.row,goodskey,scope.$index)" size="mini" type="primary" icon="b2b-gouwuche iconfont"> 加入购物车</el-button>
						<div v-else>库存不足</div>
					</template>
				</el-table-column>
			  </el-table>
			</el-dialog>

		</div>
		<div v-if="[1,3].includes($store.state.app.tenantshoppingmallconfig.goodsvivewtype)">
			<div class="pwidth  bg-f m-b-10 gline" v-for="(item,index) in goods" :key="index">
				<div class="list">
					<div class="goodsimg relative" @click="godetail(item.id)">
						<img class="w-210 h-210" v-if="item.imageurl" :src="item.imageurl" onerror="javascript:this.src='../assets/img/mr.png';">
						<img v-if="!item.imageurl" src="../assets/img/mr.png" alt="">
						<img v-if="item.specialoffer==1" class="absolute t-0 l-0 " style="width: 35px;height: 40px;" src="../assets/img/teja.png">
					</div>
					<div class="detail ">
						<div class="p-t-20 flex">
							<div class="m-b-10">
								<div @click="godetail(item.id) " class="cursor m-b-10">
									<span class="f-16"> {{item.goodsname}}</span>
									<i class="f-12 h-20 i-b l-h-16" v-if="item.isprescription">{{item.isprescription? 'RX':'' }}</i>
									<i class="f-12" v-if="item.isephedrine">{{item.isephedrine? '麻黄碱':''}}</i>
								</div>
								<!-- 促销 -->
								<div class="">
									<div class="sell i-b c-f p-0-10 f-12 m-r-10 relative o-h rad-40 " v-for="(cuxiao,cuxiaoindex) in item.promotesalesList" :key="cuxiaoindex">
										<img class="w-60 h-20 absolute l-10  l-0" src="../assets/img/cx2.png" alt=""> 
										<span class="relative z-i-10"><span class="bold m-r-10">{{cuxiao.salesname}}</span>{{cuxiao.promoteTitle}}</span>
									</div>
								</div>
							</div>

							<div class="cursor p-r-20 " @click="addCollection(item.id,item.isfav)">
								<i class="iconfont c-9 relative t-1 m-r-5 f-16" :class="{'b2b-shoucang':item.isfav==0,'b2b-shoucang ':!item.isfav,'b2b-yishoucang c-ff8 ':item.isfav==1}"></i>
								<span>{{item.isfav?'已收藏':'收藏'}} </span>
							</div>
						</div>

						<div class="describe relative p-r-20">
							
							<ul class="bg-f9 p-5-10">
								<li class="ell">{{goodsview[$store.state.app.goodsvivewtype].tymc}}：{{item[goodsview[$store.state.app.goodsvivewtype].tymcval]}}</li>
								<li class="ell w-80 p-r-20">{{goodsview[$store.state.app.goodsvivewtype].gg}}：{{item[goodsview[$store.state.app.goodsvivewtype].ggval]}}</li>
								<li class="ell">{{goodsview[$store.state.app.goodsvivewtype].xh}}：{{item[goodsview[$store.state.app.goodsvivewtype].xhval]}}</li>
								<li> {{goodsview[$store.state.app.goodsvivewtype].zczh}}：{{item[goodsview[$store.state.app.goodsvivewtype].zczhval]}}</li>
								<li > 包装规格：{{item.pakegespce}}</li>
								<li>{{goodsview[$store.state.app.goodsvivewtype].bzgg}}：{{item[goodsview[$store.state.app.goodsvivewtype].bzggval]}}</li>
								<li class="ell p-r-10"> 生产厂家：{{item.manufacturersname}}</li>
								<li v-if="$store.state.app.token&&item.retailprice&&$store.state.app.showprice!=1"> 建议零售价：￥{{item.retailprice}}</li>
								<li > 医保编码：{{item.ybcode}}</li>
								<li v-if="showStock==1"> 库&nbsp;&nbsp;&nbsp;存：{{item.totalstock}}</li>
								<li> 产品单位：{{item.unit}}</li>
								
								
							</ul>
							<div class="m-t-15 m-b-10 relative">
								<p class="bg-f h-20 c-9 absolute r-0 t--8 p-0-10" v-if="item.littleqty!=0&&item.littleqty!=1">注：最小添加数量单位为{{item.littleqty||1}}</p>
							</div>
						</div>
					</div>
				</div>
				
				
				<!-- 批号列表 -->
				<div class=" p-20 " v-if="item.batchInfoList.length">
					<li class="flex h-30 l-h-30 m-b-10" v-for="(batchInfoitem,batindex) in item.batchInfoList" :key="batindex">
						<div class="flex w100 start">
							<p v-if="batchInfoitem.batchno=='defaultbno'" class="w-80"><span class=" --b-a tc --color h-16 p-2-5 f-12 relative t-2">默认分配</span></p>
							
							<div v-if="batchInfoitem.batchno!='defaultbno'" class="flex">
					<!-- 			<span class=" border-a-ff4-1 tc c-ff4 h-20 l-h-16 m-r-10 m-t-5 f-12 w-40">促销</span> -->
								<p class="w-150">批号：{{batchInfoitem.batchno}}</p>
							</div>
							<p class="l-h-35 m-r-10 w-190">业务组：{{batchInfoitem.groupname}}</p>
							
							<p class="w-160"><span v-if="batchInfoitem.batchno!='defaultbno'"> 生产日期：{{batchInfoitem.producedate}}</span></p>
							<p class="w-140"><span v-if="batchInfoitem.batchno!='defaultbno'">效期：{{batchInfoitem.validdate}}</span></p>
							<p class="w-140 nowrap flex start " v-if="$store.state.app.token&&$store.state.app.showprice!=1"><span class="l-h-35">批发价：</span>   <price class="c-ff3" v-if="batchInfoitem" :price="batchInfoitem.price" :size="20"/> 
							</p>
						</div>
						<div class="flex ">
							<div class="h-30  m-r-20 o-h flex">
							
								<el-input-number size="mini" :step="item.fltp?item.fltp:1" v-model="batchInfoitem.qty" @blur="onblur(item,1,batchInfoitem)" :min="item.fltp?item.fltp:1"  :precision="0" :max="batchInfoitem.stock" label="描述文字"></el-input-number>
							</div>
							<el-button class="tc addcardbtn cursor h-30 l-h-25 w-110 " icon="iconfont b2b-gouwuche"  :disabled="!ishshowcart(batchInfoitem.qty,batchInfoitem.stock)" :type="ishshowcart(batchInfoitem.qty,batchInfoitem.stock)?'primary':'info'"
								@click="addcarorder(item,batchInfoitem,index,batindex)">
									
								<span class="h-30 " v-if="$store.state.app.Operational"> {{$store.state.app.Operational.goodlist.addcart}}</span>
							</el-button>
					
							
						</div>
					</li>
				</div>
							
				
				
			</div>
		</div>
	</div>
	
	
</template>

<script>
	var showStock = sessionStorage.getItem('showStock');
	var allowPlaceAnOrder = sessionStorage.getItem('allowPlaceAnOrder');
	var groupnos =  sessionStorage.getItem('salegroups ')

	if(groupnos&&groupnos!='undefined')groupnos=JSON.parse(sessionStorage.getItem('salegroups '))[0]
	// var groupnos =  sessionStorage.getItem('salegroups ')!='undefined'?JSON.parse(sessionStorage.getItem('salegroups '))[0]:'';
	export default{
		props:['goods','isline'],
		data() {
			showStock = sessionStorage.getItem('showStock');
			allowPlaceAnOrder = sessionStorage.getItem('allowPlaceAnOrder');
			groupnos =  sessionStorage.getItem('salegroups')
		
			return{
				time: 1, //定时
				num:1,
				showStock:sessionStorage.getItem('showStock'),
				selectbatch:false,
				goodsbatchInfoList:[],
				batchInfoList:[],
				goodskey:'',
				pricetype: {'1': '折扣' ,'2' :'立减价格' ,'3' :'秒杀',  '5':'买赠'},
			}
		},
		
		methods:{
			
		
			showselectbatch(item,index){
				
				console.log('加入item',item.batchInfoList.length)
				if(item.batchInfoList.length==1){
					console.log('单批号',item)
					item.batchInfoList[0].qty=item.qty
					this.selectbatch=false
					// if(item.fltp)item.qty
					
					this.addcarorder(item,item.batchInfoList[0],index,0)
					
				}
				if(item.batchInfoList.length>1){
					console.log('多批号',item)
					item.batchInfoList.map(res=>{res['qty']=0})
					this.batchInfoList=item.batchInfoList
					this.goodsitem=item
					this.goodskey=index
					this.selectbatch=true
				}
				
				
				
			},
			
			
			handleChange(){
				
			},
			
			
			// 加入收藏
			addCollection(goodsid, type) {
				var {goods}=this
				this.addCollections(goodsid, type).then(res=>{
					for (var i in goods) {
						if (goods[i].id == goodsid) {
							goods[i].isfav = goods[i].isfav ? 0 : 1;
							this.goods=goods
						}
					}
				})
					
			},
			
			// 限制最小采购值
			onblur: function onblur(item, typs, batchInfoitem) {
				console.log(item); // 有批号
			
				if (typs) {
					this.goods.filter(function(list) {
						if (list.id == item.id) {
							for (var i in list.batchInfoList) {
								if (list.batchInfoList[i].batchno == batchInfoitem.batchno) {
									if (item.littleqty != 0) {
										if (parseInt(batchInfoitem['qty']) <= item.littleqty) {
											console.log('batchInfoitem小于', parseInt(batchInfoitem['qty']));
											list.batchInfoList[i]['qty'] = item.littleqty;
										} else {
											console.log('batchInfoitem大于', parseInt(batchInfoitem['qty']));
											list.batchInfoList[i]['qty'] = Math.ceil(parseInt(batchInfoitem['qty']) / item.littleqty) * item.littleqty;
										}
									} else {}
								}
							}
						}
					});
				} else {
					// 无批号
					this.goods.filter(function(list) {
						if (list.id == item.id) {
							if (parseInt(item['qty']) <= item.littleqty) {
								console.log('小于', parseInt(item['qty']));
								list['qty'] = item.littleqty*1;
							} else {
								console.log('大于', parseInt(item['qty']));
								item.littleqty=item.littleqty==0?1:item.littleqty
								list['qty'] = Math.ceil(parseInt(item['qty']) / item.littleqty*1) * item.littleqty*1;
							}
						}
					});
				}
			
				console.log(this.goods);
			},
			
			
			// 是否显示加入购物车
			ishshowcart(qty,stock){
				
				if(allowPlaceAnOrder==0){
					if(qty>stock){
						return false
					}else{
						return true
					}
				}else{
					return true
				}
			},
			
			ishshowcartcard(item){
				if(allowPlaceAnOrder==0){
					if(item.batchInfoList.length==0){
							return false
					}
					if(item.batchInfoList.length==1){
						if(item[0].qty>item[0].stock){
							return false
						}else{
							return true
						}
					}
					
					
					
				}else{
					return true
				}
			},
			
			
			
			// 数量
			reduce(item, batchInfoitem, index) {
				// 批号加数量
				if (batchInfoitem) {
					var value;
					if (item.littleqty != 0) {
						value = index ? batchInfoitem.qty * 1 + item.littleqty : batchInfoitem.qty * 1 - item.littleqty;
						value < item.littleqty ? value = item.littleqty : value = value;
					} else {
						value = index ? batchInfoitem.qty * 1 + 1 : batchInfoitem.qty * 1 - 1;
						value < 1 ? value = 1 : value = value;
					}
			
					this.goods.filter(function(list) {
						if (list.id == item.id) {
							for (var i in list.batchInfoList) {
								if (list.batchInfoList[i].batchno == batchInfoitem.batchno) list.batchInfoList[i]['qty'] = value;
							}
						}
					});
				} else {
					// 无批号加数量
					var _value;
					if (item.littleqty > 0) {
						_value = index ? item.qty * 1 + item.littleqty : item.qty * 1 - item.littleqty;
						_value < item.littleqty ? _value = item.littleqty : _value = _value;
					} else {
						_value = index ? item.qty * 1 + 1 : item.qty * 1 - 1;
						_value < 1 ? _value = 1 : _value = _value;
					}
					this.goods.filter(function(list) {
						if (list.id == item.id) {
							list['qty'] = _value;
						}
					});
				}
			},
			
			// 加入购w物车
			addcarorder: function addcarorder(item, batchInfoitem,index,batindex) {
				var showStock = sessionStorage.getItem('showStock');
				console.log(item,batchInfoitem,index,batindex)
				item['batchnosalegroupname']=batchInfoitem.groupname
				item['batchnosalegroupno']=batchInfoitem.groupno
			
				
				// console.log('6666',item)
				var _this2 = this;  //isvisibl当前业务员是否有权限对个商品下单 0为没有权限
				if (!this.time) return; // var batchno=batchInfoitem.batchno=="defaultbno"?'默认批号':batchInfoitem.batchno
				if(item.isvisible==0){this.$message({message: '您暂无权限',type: 'warning'});;return}
				this.time = 0;
				if(batchInfoitem){
					var showststockn = "库存不足";
					if (showStock != 0) showststockn = '目前库存为' + batchInfoitem.stock;
					// debugger
					if (batchInfoitem.qty > batchInfoitem.stock) {
						if(allowPlaceAnOrder==1){
							if (batchInfoitem.batchno == "defaultbno") {
								this.$confirm('您购买的【' + item.goodsname + '】、批号【默认批号】、' + showststockn + '，商品下次到货时间预计为【' + item.arrivalday +
									'】天，是否继续?', '提示', {
										confirmButtonText: '确定',
										cancelButtonText: '取消',
										customClass:this.color,
										type: 'warning',
										callback:(action)=>{
											if(action=='confirm'){
												if (batchInfoitem) this.addcar(item, batchInfoitem.batchno, batchInfoitem.qty).then(res=>{this.changestock(index,item.qty,batindex)});
												if (!batchInfoitem) this.addcar(item, '', item.qty).then(res=>{this.changestock(index,item.qty,batindex)});
												_this2.time = 0;
												setTimeout(function() {_this2.time = 1;}, 1000);
											}else{
												_this2.time = 1;
											}
										}	
									})
							} else {
								this.$message.error( showststockn + '，无法加入，请修改采购数量');
							}
						}else{
							this.$message.error( showststockn + '，无法加入，请修改采购数量');
						}
					} else {
						console.log(item.qty,item.totalstock)
						
							if (batchInfoitem) this.addcar(item, batchInfoitem.batchno, batchInfoitem.qty).then(res=>{this.changestock(index,item.qty,batindex)});
							if (!batchInfoitem) this.addcar(item, '', item.qty).then(res=>{this.changestock(index,item.qty,batindex)});
						
						setTimeout(function() {_this2.time = 1;}, 1000);
					}
				}else{
					// debugger
					if(allowPlaceAnOrder==1){
						this.addcar(item, '', item.qty).then(res=>{
				
							if(item.totalstock){
								this.selectbatch=false
								this.changestock(index,item.qty)
							}
						});
					}else{
						if((item.qty<=item.totalstock)&&(item.totalstock!=0)){
							this.$message.error('当前库存、' + showststockn + '，无法加入，请修改采购数量');
						}
					}
				}
				setTimeout(function() {_this2.time = 1;}, 1000);
			},
			
			// 
			changestock(index,qty,batindex){
				console.log('修改商品列表数量',index,qty,batindex)
				if(!batindex){
					console.log(this.goods[index].totalstock,qty)
					// this.goods[index].totalstock=this.goods[index].totalstock-qty
				}else{
					// this.goods[index].batchInfoList[batindex].stock=this.goods[index].batchInfoList[batindex].stock-qty
				}
				this.time = 1;
				
			},
			
			
			// // 页面跳转到详情
			godetail: function godetail(id) {
				sessionStorage.setItem('pageno',this.pageno);
				// this.$router.push({path:'/detail',query:{goodsid:id}})
				window.open('/#/detail?goodsid='+id, '_blank');
			},
			
			
			
			
			
			
		}
	}
	
</script>

<style>
	.el-input-number--mini .el-input__inner{padding-left: 30px; padding-right: 30px;}
	.el-input-number--mini{width: 90px}
	.gline .el-input-number--mini{width: 110px}
	.xzph .el-dialog__body{padding:16px 20px 30px}
	.xzph .el-table__header th{background: rgba(2,101,255,0.1);padding: 8px 0;}
	.el-table__row{height: 40px}
	/* .xzph .el-table .el-table__row td{padding: 5px 0;} */
	.xzph .el-table:before{height: unset;}
	.xzphpoup .el-input-number--mini{width: 120px}
	.el-icon-close:before{color: #fff !important;}
	/* .xzph .el-button--mini{padding: 8px 15px !important;} */
</style>
