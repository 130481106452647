<template>
	<div >
		<div v-if="guanggao.length" >
			<div class="h-30 bg-f w100 tc f-14  l-h-30  ">
				<!-- slideshow -->
				<!-- <div class="i-b images"><img class="w-10 h-10 m-r-10" src="/img/yximg/guanggao.png" >{{guanggao}}</div> -->
				<div class="pwidth relative">
					<el-carousel height="30px" direction="vertical" :autoplay="true" :interval="3000">
						<el-carousel-item v-for="(item,index) in guanggao" :key="index">
							<div class="medium c-ff48 cursor i-b" @click="detailnews(item.id)"><img
									class="w-15 h-15 m-r-10 relative t--2" src="../assets/img/guanggao.png" />{{ item.title }}132
							</div>
						</el-carousel-item>
					</el-carousel>
					<!-- <div  @click="closegg" class="absolute r-0  cursor t-0 z-i-10"><img class="w-15 h-15  " src="/img/yximg/close.png"></div> -->
				</div>
			</div>
	</div>
	
	<div id="headerTop" v-cloak class="top">
	
		<div class="pwidth  f-12" :class="guanggao.length?'h-0':''">
			<div class="contents flex" >
				<div v-if="!$store.state.app.login.cardname">
					<span @click="()=>{$router.push({path:'/index'})}">嗨，欢迎您 {{$store.state.app.login.cardname}} ！ </span>
					<span @click="()=>{$router.push({path:'/login'})}" class="c-00b">请登录 </span>
				</div>
				<div v-if="$store.state.app.login.cardname">
					<span @click="()=>{$router.push({path:'/index'})}">欢迎来到 
					{{$store.state.app.login.cardname}}
					<!-- {{$store.state.app.configs?$store.state.app.configs[0].value:''}}！ -->
					</span>
					<span class="c-ff3" @click="logouts">退出</span>
				</div>
	
				<div class="content-right f-12 c-6">
					<ul>
						<li>
							
							<i class="iconfont m-r-2 b2b-shangchengshouye relative c-6 f-16"> </i>
							<span @click="gotoindex">商城首页</span>
						</li>
						<!-- <li v-if="$store.state.app.login.cardid">
							<i class="iconfont m-r-2 b2b-wodedingdan1 relative c-6 f-16"> </i>
							<span v-if="$store.state.app.Operational"><div @click="()=>{$router.push({path:'/member'})}">{{$store.state.app.Operational.header.myorder||0}}</div></span>
						</li> -->
						<!-- <li class="l-h-36 flex">
							<i class="iconfont b2b-pijianbaogao c-6 t-2 relative"> </i>
							<span v-if="$store.state.app.Operational"><div @click="pjbg">批检报告</div></span>
						</li> -->
						
						<!-- <li>
							<div @click="()=>{$router.push({path:'/cart'})}">
								<el-badge :value="$store.state.app.cartnumber" class="item m-r-5">
									<i> <img src="../assets/img/gwc.png" alt=""></i>
								</el-badge>
								<span v-if="$store.state.app.Operational">{{$store.state.app.Operational.header.cart}}</span>
							</div>
						</li> -->
						<li>
							<i class="iconfont m-r-2 b2b-yonghuzhongxin relative c-6 f-16"> </i>
							<span v-if="$store.state.app.Operational"><div @click="()=>{$router.push({path:'/member'})}">{{$store.state.app.Operational.header.MembershipCenter}}</div> </span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	
	</div>
	
		<customerservice v-if="contact!=1"/>
	</div>
</template>

<script>
	import customerservice from './customerservice.vue';
	export default{
		components:{customerservice},
		data() {
			
			return{
				contact:sessionStorage.getItem('contact'),
				// configsname:configsname,//商城名称
				commands: '',
				options: [],
				orgno: '',
				// cardid: cardid,
				paytype: null,			//支付方式
				paytypedialog: false,			//显示选着支付的弹窗
				selectpaytypekey: 0,			//当前选中的支付方式id
				payTradeid: '',			//要支付的订单号
				paycode: '0',			//是否显示支付二维码 0不显示 1显示 
				erpcustno: this.$store.state.app.login.erpcustno == 'undefined' ? '' : this.$store.state.app.login.erpcustno,			//公司编码
				creditdata: '',			//信用付额度
				chageerpcustno: false,			//是否显示选择客户弹窗
				querymycustomerlist: [{
					value: '选项1',
					label: '黄金糕'
				}],			//业务员的客户列表
				companyname: this.$store.state.app.login.companyname,
				guanggao:[], //广告
				showguanggao:false,
				openguanggao:false,
				Operational:'',
			}
		},
	
		computed: {
			isFollow() {
				return this.$store.state.app.newslist
			}
		},
		watch: { 
			isFollow() {
				this.guanggao=this.$store.state.app.newslist.SCXW
				if(this.guanggao.length)this.$store.dispatch('PUBLIC',{type:'showguanggao',res:true})
			} 
		},
		
		created() {
			// this.cartNumber(); //查询购物车数量
			// this.gaungg()	
		},
		methods:{
			pjbg(){
				sessionStorage.setItem('headeractive',3)
				this.$store.dispatch('PUBLIC',{type:'headeractive',res:3})
				this.$router.push({path:'/index'})
			},
			
			
			// // 切换分公司
			// handleCommand: function handleCommand(erpcustno) {
			// 	sessionStorage.setItem('erpcustno', erpcustno);
			// 	for (var i in this.querymycustomerlist) {
			// 		if (this.querymycustomerlist[i].erpcustno == erpcustno) {
			// 			this.companyname = this.querymycustomerlist[i].companyname;
			// 			sessionStorage.setItem('companyname', this.querymycustomerlist[i].companyname);
			// 			this.$notify({title: '成功',message: '切换成功',type: 'success'});
			// 			setTimeout(function() {
			// 				location.reload();
			// 			}, 1000);
			// 		}
			// 	}
			// 	// inquirecarnumb(); 
			// },
			// 退出
			logouts() {
				console.log('退出');
				this.logout();
			},
			
			// 首页
			gotoindex() {
				this.$store.dispatch('PUBLIC',{type:'headeractive',res:0})
				this.$router.push({path:'/index'})
			},
			
			
			// 广告滚动
			gaungg(){
				var self=this
				var data = {categoryno:'SCXW',pageno: 1,pagesize: 10};
				this.requset('/kbhelper/findarticlebycategory', data).then(data=>{
					console.log('广告滚动',data);
					if (data.status = "success") {
						self.guanggao=data.data
						
						if(data.data.length) this.openguanggao=true,this.$store.dispatch('PUBLIC',{type:'showguanggao',res:true})
					}
				});
				
			},
			
			detailnews(id) {
				this.$router.push({path:'/newslist',query:{detailid:id,showdetail:true}})
				// window.location.href = '/view/news/newsDetail.html?id=' + id;
			},
		}
	}
</script>

<style>
</style>
