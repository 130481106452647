<template>
	<div>
		<!-- 支付方式弹窗 -->
		<div class="payloaging">
			<el-dialog width="950px" :title="'请选择'+$store.state.app.Operational.goodlist.paytype" :show-close="false" :visible.sync="paytypedialog" @close="close">
				<div class="m-b-40">
					<ul class="flex wraps p-l-20  start">
						<li class="cursor m-b-20 m-r-20" v-for="(item,index) in paytype"
							@click="selectpaytype(item.payid)">
							<div class="b-a-dcd tc p-0-10 relative h-125 rad-8" style="min-width: 125px;" v-if="item.payid!=5" :class="selectpaytypekey==item.payid?'border-a-00b-2 ':''">
								<img class="w-40 h-40 m-t-30 m-b-10" :src="item.icon">
								<div class="m-b-5">{{item.methodname}}</div>
								<div v-if="item.payid==8"> 对公账号：{{item.zjaccount}}</div>
								<img src="../assets/img/goux.png" v-if="selectpaytypekey==item.payid" class="w-24 h-24 absolute b--2 r-0">
							</div>
		
							<div class="  tc p-0-10 relative w-125 h-125 rad-8" v-if="item.payid==5&&erpcustno" :class="selectpaytypekey==item.payid?'border-a-00b-2':erpcustno?'b-a-dcd':''">
								<img class="w-40 h-40 m-t-30 m-b-10" :src="item.icon">
								<div class="relative m-b-10" v-if="item.payid==5&&erpcustno">{{item.methodname}}</div>
								<div v-if="creditdata" class="f-10 c-ffa m-b-10">可用额度：{{creditdata.remainamount}}</div>
								
								<img src="../assets/img/goux.png" v-if="selectpaytypekey==item.payid" class="w-24 h-24 absolute b--2 r-0">
							</div>
						</li>
					</ul>
				</div>
		
				<div class="flex f-14  tc   w100 l-0 p-l-120 p-r-120 s-c">
					<p class="w-100 rad-2 h-34 l-h-32 c-b14e cursor border-a-00b-1 m-r-20" @click="payment(0)">取消</p>
					<p class="w-100 rad-2 h-34 l-h-34 bg-00B c-f cursor" @click="payment(1)">确定</p>
				</div>
			</el-dialog>
		</div>
		
		<!-- 微信支付页面 二维码-->
		<div v-if="wxpaycode">
			<div class="fix bg-0-5 w100 h100 top-0 qrcode o-h l-0" >
				<div class="qrcodema " ref="qrCodeUrl"><img :src="qrCodeUrl"/></div>
				<div class="bg-f h-500 w-400 rad-10 absolute l-0 r-0 top-0 b-0 m-a">
					<div class=" absolute b-10 p-20 w-400 tc">
						<p class="f-20 tc m-b-30">微信扫码支付</p>
						<div class="flex m-t-20 f-16 p-0-30 s-c">
							<p class="w-100 h-40 rad-2 border-a-00b-2 c-b14e l-h-40 cursor m-r-20" @click="calcenpay">取消支付</p>
							<p class="w-100 h-40 rad-2 bg-00B c-f l-h-40 cursor" @click="Paymentsuccessful">支付完成</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		
	</div>
	
</template>

<script>
	var login = sessionStorage.getItem('login');
	var companytype = sessionStorage.getItem('companytype');

	import QRCode from 'qrcode'
	export  default{
		data(){
			login = sessionStorage.getItem('login');
			companytype = sessionStorage.getItem('companytype');
			return{
				paytype:[],
				selectpaytypekey:'',
				qrCodeUrl:'',
				wxpaycode:false,
				login: JSON.parse(login),
				payloading:false
			}
		},
		
		props:['paytypedialog','tradeid'],
		
		created() {
			this.getpaytype()
			this.credit().then(res=>{})
		},
		
		methods:{
			
			//获取支付方式POST 开发完成 /shoppingcart/selectPayment
			getpaytype: function getpaytype() {
				var selectPayment =sessionStorage.getItem('selectPayment')
				if(selectPayment)this.paytype=JSON.parse(selectPayment)
				// var {login}=this
				// console.log('login',login)
				// this.requset('/shoppingcart/selectPayment', {}).then(data=>{

				// 	if(companytype==2){
				// 		var xjpay=[]
				// 		data.data.map((res,index)=>{
				// 			// xjpay.push(res)
							
				// 			if(res.payid==6)xjpay.push(res),this.paytype=xjpay
				// 			if(res.payid==8)xjpay.push(res),this.paytype=xjpay
				// 		})
						
				// 	}else{

				// 		var pau=[]
				// 		data.data.map((res,index)=>{
				// 			if(res.payid!=6&&res.payid!=8)pau.push(res)
				// 		})
				// 		this.paytype=pau
				// 		console.log('xjpay',pau)
				// 	}

				// 	this.selectpaytypekey=this.paytype[0].payid
				// 	sessionStorage.setItem('paytype', JSON.stringify(data.data));
				// });

			
			},
			
			// 选择支付方式
			selectpaytype: function selectpaytype(key) {
				this.selectpaytypekey = key;
			},
			
			close(){
				this.$parent.paytypedialog=false
			},
			
			//发起支付
			payment: function payment(iscancel) {
				var selectpaytypekey = this.selectpaytypekey,
				paytype = this.paytype;
				console.log('agadgasdb',this.tradeid,selectpaytypekey)
				if(this.payloading)return   this.$message.error('提示，请不要频繁操作');
				this.payloading=true
				if (iscancel) {
					if (selectpaytypekey == 1) this.wxpay(); //微信支付
					if (selectpaytypekey == 5) this.xinyongpay(5); //信用付
					if (selectpaytypekey == 6) this.xinyongpay(6); //现金付
					if (selectpaytypekey == 8) this.xinyongpay(8); //线下转账
					if (selectpaytypekey == 7) this.$notify.error({title: '失败',message:'未开通'});//其他支付
					if (selectpaytypekey == 3) this.Alipay(this.tradeid); //支付宝
					if (selectpaytypekey == 10) this.unionpay(this.tradeid); //支付宝
				} else {
					this.$parent.paytypedialog=false
				}
				setTimeout(()=>{this.payloading=false})
			},
			
			// 生成微信支付二维码
			wxpay: function wxpay() {
				var _this2 = this;
				this.$parent.paytypedialog=false
				var paydata = {
					tradeid: this.tradeid,
					pay: 'pay'
				};
				console.log('agadgasdb',this.tradeid)
				this.requset('/wxpay/tradepay', paydata,{met:'pay'}).then(payres=>{
					console.log(payres)
						if (payres.status == "success") {
							if(payres.data.return_code!="FAIL"){
								QRCode.toDataURL(payres.data.code_url).then(url => {
									console.log(url)
									this.qrCodeUrl=url
									this.wxpaycode=true
								  })
								  .catch(err => {
									console.error(err)
								  })
							}else{
								_this2.$notify.error({title: '失败',message: payres.data.return_msg});
							}
						}else{
							_this2.$notify.error({title: '失败',message: payres.message});
						}
					});
			
				
			},
			
			
			// 现金和信用支付
			xinyongpay: function xinyongpay(type) {
				var _this3 = this;
				var url = '';
				if (type == 5) url = '/trade/creditpay';
				if (type == 6) url = '/trade/cashpay';
				if (type == 8) url = '/trade/transferpay';
				var data = {tradeid: this.tradeid};
				this.requset(url, data).then(data=>{
						this.$parent.paytypedialog=false
						if(data.status=='success'){
							_this3.$notify({title: '成功',message: '操作成功',type: 'success'});
							this.$parent.orderlist()
							this.credit();
						}else{
							_this3.$notify.error({title: '失败',message: data.message});
						}
						console.log(data)
					}).catch(function(res) {
						_this3.$notify.error({title: '失败',message: res.message});
					});
				
			
			},
			
			// 取消微信支付
			calcenpay() {
				this.wxpaycode=false
			},
			
			
			// 点击微信支付完成
			Paymentsuccessful: function Paymentsuccessful() {
				var _this4 = this;
			
				var url = '/trade/info';
				var data = {tradeid: this.tradeid};
				this.requset(url, data).then(data=> {
						console.log(data);
						if (data.status = "success") {
							if (data.data.tradeinfo.flag != 1) {
								_this4.$notify.error({title: '支付失败',message: '您还没有支付成功，请先支付后再试！'});
								this.wxpaycode=false
								this.$parent.orderlist()
			
							} else {
								_this4.$notify.error({title: '支付失败',message: '您还没有支付成功，请先支付后再试！'});
							}
						}
					});
			},
		}
	}
	
	
</script>

<style>
</style>
