<template>
	<div class="pwidth">
	    <div class=" arrow l-h-50 m-t-10 m-b-10">
	        <el-breadcrumb separator-class="el-icon-arrow-right">
	            <el-breadcrumb-item>你所在的位置：</el-breadcrumb-item>
	            <el-breadcrumb-item> 批检报告</el-breadcrumb-item>
	
	        </el-breadcrumb>
	    </div>
	
	    <div class="m-b-20  bg-f min-h-500" >
	
	
			<div class="p-20">
			    <div>
			        <h3 class="m-b-20"><i class="shu m-r-5"></i> 批检报告下载</h3>
					
					<el-form ref="form" :model="form" label-width="68px" >
						<div class="flex">
							<div class="w29">
								<el-form-item label="通用名称">
									<el-input v-model.trim="form.name" placeholder="请输入通用名称"></el-input>
								</el-form-item>
							</div>
						  
						  <div class="w29">
						  	<el-form-item label="商品批号">
						  		<el-input v-model.trim="form.batchno" placeholder="请输入商品批号"></el-input>
						  	</el-form-item>
						  </div>
						  
						  <div class="w29">
						  	<el-form-item label="批准文号">
						  		<el-input v-model.trim="form.approvalno" placeholder="请输入批准文号"></el-input>
						  	</el-form-item>
						  </div>
						  <div class="">
						  	<div class="w-60 h-40 l-h-40 tc c-f bg-b14e rad-4 cursor" @click="getgoodslsit">搜索</div>
						  </div>
						</div>
					 </el-form>
			    </div>
				
				<div v-if="goodslist.length">
				    <h3 class="m-b-20"><i class="shu-2 m-r-5"></i>查询结果</h3>
					<div v-for="(item,index) in goodslist" :key="index" class="m-b-20">
						<img class="w-20 h-20" src="../../assets/img/pdf.png" >
					    <span class="w-400 i-b">{{item.batchno}}{{item.goodsname}}.pdf</span>
					
					    <span class="c-b14e cursor border-a-00b-1 p-5-10 l-h-25" @click="downloadExcel(item.goodsno,item.batchno||'')">
					        <i><img src="../../assets/img/download.png" alt=""></i>
					        点击下载
					    </span>
					</div>
				</div>
					<div v-if="!goodslist.length" class="tc ">
						<img class="w-200 h-150 m-t-100 c-9" src="../../assets/img/sc-1.png" >
						<p>暂无数据</p>
						
					</div>
					 
					 
			 </div>
	    </div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				headerNav: '',
				form: {
				  name: '',
				  goodsno: '',
				  batchno: '',
						approvalno:'',
				},
				goodslist:[],
			}
		},
		created: function created() {
			this.headerNav = sessionStorage.getItem('headerNavs');
		},
		methods: {

			//查询商品列表
			getgoodslsit() {
				var form=this.form
				var self = this;
				var Url = '/kbhelper/findPJBG';
				var data = {
					goodsname:form.name,
					batchno:form.batchno,
					approvalno:form.approvalno
				};
				
				this.requset(Url, data).then(function(res) {
					console.log(res)
					self.goodslist=[res.data]
				});
				
			},
			

			
			  // 下载POST 
			downloadExcel(goodsno,batchno) {
				var self=this
				var orgno = sessionStorage.getItem('orgno');
				var tenantid = sessionStorage.getItem('tenantid');
				var url =this.serve+ "/b2bgoods/goodsdata?tenantid=".concat(tenantid, "&orgno=").concat(orgno, "&goodsno=").concat(goodsno) + (batchno !== '' ? '&batchno=' + batchno : '');
				console.log(url)
				var xhr = new XMLHttpRequest();
				xhr.open('POST', url, true); // 也可以使用POST方式，根据接口
				xhr.responseType = "blob"; // 返回类型blob
				xhr.onload = function () {

				  if (this.status === 200) {
					var blob = this.response;
					var reader = new FileReader();
					console.log( blob)
					reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
					reader.onload = function (e) {
					  var a = document.createElement('a');
					  a.download = self.genUUID() + (batchno !== '' ? '_' + batchno : '') + '.zip'; //下载文件名
					  a.href = e.target.result;
					  a.click();
					};
				  }
				}; 
				xhr.send();
			},
			
			genUUID() {
				return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
					var r = Math.random() * 16 | 0,
						v = c == 'x' ? r : r & 0x3 | 0x8;
					return v.toString(16);
				});
			}
		}
	}
</script>

<style>
	.el-form-item__label{color:#666;}
</style>
