<template>

	<div class="home" >
		<!-- <iframe width="1000px" src="/#/index/new_file.html" frameborder="0"></iframe> -->
		<div >
			<div :class="'portaltoptemplate'+$store.state.app.tenantshoppingmallconfig.portaltoptemplate" 
			:style="{background: brandArr.length?brandArr[backgroundcolorid].backgroundcolor:''}">
				<div class="w-1200 h-410 m-t-10 flex m-b-10 m-a">
					<classify ref="classify"/>
					<div class="w-800 ">
						<template>
							<el-carousel :interval="3000" height="410px" @change="carouselchange" >
								<el-carousel-item v-for="(item,index) in brandArr" :key="index">
									<a class="p-l-10" :href="item.linkurl"><img  :src="item.imageurl" alt=""></a>
								</el-carousel-item>
							</el-carousel>
						</template>
					</div>
					<classRight :linetotal="linetotal"/>
				</div>
			</div>
		</div>
		
	
		<div class="pwidth m-t-10">
			<div v-loading="loading" class="customindex" :class="{[$style.previewBox]: isPreview}">
				<div v-for="item in trueComponentList" :key="item.id" :slot="item.$$slot || 'default' "
					:class="{
						draggableSlot: item.$$slot,
						draggableItem: !item.$$slot,
						[`draggableSlot_${item.$$slot}`]: item.$$slot
					}">
					<ViewComponentWrap :editor-item="item" :is-preview="isPreview" >
						<component :is="item.componentViewName" slot="componentView" :form-data="item.componentValue"> </component>
					</ViewComponentWrap>
				</div>
								
			</div>

		</div>
		
		<customer :showcustomer="showpoup" ref="customer"/>
	</div>

</template>

<script>
	
	var openid = sessionStorage.getItem('openid');
	var erpcustno = sessionStorage.getItem('erpcustno');
	import md5 from 'md5';
	import guidutil from '../../lib/guidutil.js'
	import customer from '../../components/customer.vue';
	import classify from '../../components/classify.vue';
	import classRight from '../../components/classRight.vue';
	var guidobj = new guidutil();
	
	import VueElementForm, { schemaValidate	} from '@lljj/vue-json-schema-form';
	import ViewComponentWrap from '../edit/editor/components/ViewComponentWrap.vue';
	import {vm2Api,api2VmToolItem} from '../edit/editor/data.js';
	import configTools from '../edit/editor/config/tools.js';
	import configDefaultItems from '../edit/editor/config/defaultItems';
	import {getComponentsAndInitToolsConfig} from '../edit/editor/common/utils';
	import {generateEditorItem} from '../edit/editor/common/editorData';

	// 工具栏配置的组件
	const components = getComponentsAndInitToolsConfig(configTools);
	
	export default{
		components:{customer,classify,classRight,...components,			ViewComponentWrap},
		data(){
			var _this = this;
			
			return {
				// 登录--------------------------------------
				showpoup:false,//显示弹窗
				authCode: '',
				ruleForm: {
					cardno: '',
					checkPass: '',
					code: '',
					guid:''
				},
				cardnonumber: '',
				
				options: [],
				// ----登录---------------------------------

	
				headerNav: '', //当前页面
				cooperationIMG: [], // 合作商家品牌,
				brandArr: [], //轮播图
				getclassifyArr: [], //分类数据
				getnumberArr: [] ,//获取各种数量
				
				// 自定义装修
				loading: false,
				isPreview: true,
				configTools,
				scale: 100,
				editComponentList: [],
				editHeaderComponentList: [], // 兼容header slot ，插件内部实现导致必须分割多分数据
				editFooterComponentList: [], // 兼容footer slot ，插件内部实现导致必须分割多分数据
				showToolBar: true,
				backgroundcolorid:0,//banner背景色
				linetotal:0,//上架总数量
			}
		},

		created: function created() {
			console.log('location.href',location.href)
			if (erpcustno == "undefined") this.logout() 
			this.headerNav = sessionStorage.getItem('headerNavs') || 0; 
		
			this.changeCodes(); //// 刷新验证码
			// this.getbrand(''); //品牌
			this.pcbannerlist(); //轮播
			// if (this.$store.state.app.token) this.getnumber(); //登录后显示数量
			this.initEditorData();
			this.selectaonlinetotal()
			this.getpaytype()
			console.log(888888888,this.$store.state.app.tenantshoppingmallconfig)
			
		},
		mounted() {
			this.$refs.classify.getclassify()
			this.Backtop()
		},
		computed: {
			dragOptions() {
				return {
					animation: 300,
					group: 'listComponentsGroup',
					disabled: this.isPreview,
					ghostClass: this.$style.ghost,
					filter: this.$style.disabled,
					draggable: '.draggableItem',
					tag: 'div',
					swapThreshold: 0.3,
					// forceFallback: true
					// fallbackTolerance: 0
				};
			},
			// 头部、中间、底部各个list集合
			componentListGroup() {
				return [this.editHeaderComponentList, this.editComponentList, this.editFooterComponentList];
			},
		
			// 真实使用的组件 - 包含顶部、底部、不可拖动的模块平铺
			trueComponentList() {
				console.log('this.componentListGroup', this.componentListGroup)
				return [].concat(...this.componentListGroup);
			},
		
		
			// 计算出各个模块当前的使用数量
			currentUseComponentNum() {
				return this.trueComponentList.reduce((preVal, curVal) => {
					preVal[curVal.componentViewName] = preVal[curVal.componentViewName] ?
						(preVal[curVal.componentViewName] + 1) :
						1;
					return preVal;
				}, {});
			}
		},
		
		methods: {
			getpaytype() {
				this.requset('/b2bshoppingcart/selectPayment', {}).then((data)=>{
					this.allpaytype=data.data
					sessionStorage.setItem('selectPayment',JSON.stringify(data.data))
				});
			},
			async initEditorData() {
				// 使用默认值
				// let ComponentList=JSON.parse(sessionStorage.getItem('ComponentList'))
				// console.log('ComponentList',ComponentList)
				
				let ComponentList= this.$store.state.app.tenantshoppingmallconfig.portalcontenttemplateString
				const dataList = api2VmToolItem(configTools, ComponentList||configDefaultItems);
				sessionStorage.setItem('ComponentList',ComponentList)
				// 重新插入数据
				dataList.forEach((toolItemData) => {
					if (!toolItemData.componentPack) {
						console.warn('存在一条异常数据，请检查：');
						console.log(dataList);
						return;
					}
					const editorData = generateEditorItem(toolItemData);
					
					// 模拟拖入组件插入数据
					this.editComponentList.push(editorData);
					if (editorData.additional) {
						// 新加的元素处理特殊配置信息
						// this.additionalStrategy(editorData.additional, editorData);
					}
				});
			},
			
			// console.log('轮播',e)
			carouselchange(e){
				this.backgroundcolorid=e
			},
			
			
			loginout: function loginout() {
				this.logout();
			},
			
			
			// 验证码
			changeCodes: function changeCodes() {
				this.ruleForm.guid = guidobj.newGUID();
				this.authCode = this.serve + "/authCode?guid=" + this.ruleForm.guid;
			},
			
			
			
			submitForm: function submitForm(formName) {
				var _this3 = this;
				this.$refs[formName].validate(function(valid) {
					if (valid) {
						_this3.login();
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			
			resetForm: function resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			
			
			// 登录
			login: function login() {
				var self = this;
				var {cardno,checkPass,company,code,guid} = this.ruleForm
				var Url = '/b2bmembercard/login';
				var data = {
					loginname: cardno,
					psw: md5(checkPass).toUpperCase(),
					guid: guid,
					authCode: code,
					url: this.urlhost
				};
				this.ruleForm.checkPass=''
				this.ruleForm.code=''
				this.logins(Url, data).then((data)=>{
					this.ruleForm.checkPass=''
					this.ruleForm.code=''
					if (data.status == 'success') {
						if (data.data.issaleman != 1) self.$notify({title: '成功',message: '登录成功',type: 'success'});
						this.showpoup=true
						this.$refs.customer.querymycustomer()
					} else {
						self.$notify.error({title: '错误',message: data.message});
					}
				});

			},
			

			
			
			// 点击品牌跳转列表
			bannerbtn: function(item) {
				
				console.log(item.brandname)
				this.$router.push({path:'/goodslist',query:{brandname:item.brandname,brandid:item.id}})
			},

			//获取品牌数据列表POST 开发完成 /goodsfront/hotbrands POST /b2bgoods/brands
			getbrand: function getbrand(query) {
				var Self = this;
				var Url = '/b2bgoods/hotbrands'; 
				var data = {openid: openid,query: query};
				this.requset(Url, data).then(function(data) {
					if (data.status == "success") {
						if (data.data.length > 11) {
							Self.cooperationIMG = data.data.splice(0, 12);
						} else {
							Self.cooperationIMG = data.data;
						}
					}
				});
			},
			
			// banner轮播图POST /kbhelper/findadv
			pcbannerlist() {
				var Url = '/kbhelper/findadv';
				var data = {openid: openid};
				this.requset(Url, data).then(res=>{
					var data = res.data;
					this.brandArr = data;
				});
			},
			
			//查询上架商品总数
			selectaonlinetotal() {
				var Url = '/b2bgoods/selectaonlinetotal';
				this.requset(Url, {}).then(res=>{
					this.linetotal = res.data;
				});
			},
			
			
			
			// 文章更多
			Morenews: function Morenews(item) {
				console.log(item);
				document.documentElement.scrollTop=0
				this.$router.push({path:'/newslist',query:{type:item,showdetail:false}})
	
			},
			// 文章详情
			articledetail: function articledetail(id, type) {
				document.documentElement.scrollTop=0
				this.$router.push({path:'/newslist',query:{type:type,showdetail:true,detailid:id}})

			},
			

			
			
			
		}
		
	}
</script>

<style>
	@import url("../../style/index/home.css");
	.home .ViewComponentWrap_active_1ORx_, .viewBox .active::after{box-shadow:unset;padding: 0;width:1200px !important}
	/* .home div{cursor: ;} */
	/* .home .ViewComponentWrap_viewBox_3Rldo{cursor:unset;position: unset;} */
	.home .ViewComponentWrap_active_1ORx_::after{box-shadow:unset !important;width:1200px !important;padding: 0;}
	
	.portaltoptemplate1{
		width: 1200px;
		background: unset !important;
		margin:10px auto;
	}
	.portaltoptemplate2{
		width: 100%;
		
	}
	.portaltoptemplate3{
		width: 100%;
	
	}
		.bg-0-01{background: rgba(0,0,0,0.1) !important}
</style>



<style module>
	@import '../edit/demo-common/css/variable.css';

	:root {
		--site-top-height: 80px;
		--tool-bar-width: 260px;
		/* --drag-area-width: 1900px; */
	}

	/*预览模式 同步样式重置*/
	.previewBox {

		.toolsBar,
		.leftCaret {
			display: none;
		}

		.container {
			height: 100vh;
			padding-left: 0;
		}

		.dragAreaWrap {
			overflow-x: hidden;
		}

		.contentBox, .dragAreaWrap {width: auto;}

		/* :global {
			.vueEditor_viewComponentBox {
				margin-left: 50%;
				transform: translate(-50%, 0);
				cursor: auto;
				box-shadow: none;

				&:after {
					display: none;
					content: none;
				}
			}
		} */
	}

	.container {
		position: relative;
		box-sizing: border-box;
		padding-left: 0;
		height: calc(100vh - var(--site-top-height));
		transition: 0.2s ease;
	}

	.hasTools {
		padding-left: var(--tool-bar-width);

		:global .el-icon-caret-right {
			transform: rotate(180deg);
		}
	}

	/*tools*/
	.leftCaret {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		position: absolute;
		width: 18px;
		height: 50px;
		background: var(--color-white);
		top: 50%;
		margin-top: -25px;
		box-shadow: 0 0 4px 0 color(var(--color-black) a(0.1));
		transition: all ease 0.3s;
		border-radius: 0 10px 10px 0;
		z-index: 9;

		&:hover {
			box-shadow: 0 0 4px 0 color(var(--color-black) a(0.2));
			opacity: 1;
		}
	}

	.toolsBar {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		background: var(--color-white);
		width: var(--tool-bar-width);
		overflow: auto;
		box-shadow: 0 0 4px 0 color(var(--color-black) a(0.2));
		z-index: 2;

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}
	}

	/*content area*/
	.contentWrap {
		position: relative;
		overflow: auto;
		height: 100%;

		&::-webkit-scrollbar {
			width: 6px;
			height: 10px;
		}

		&::-webkit-scrollbar-track {
			background-color: var(--background-color-base);
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: var(--color-text-placeholder);
		}
	}

	.contentBox {
		position: relative;
		/* width: 2000px; */
		min-height: 100%;
	}

	.dragAreaWrap {
		transform-origin: top left;
		position: relative;
		width: var(--drag-area-width);
		margin: 0 20px 0;
		overflow: auto;
	}

	.tipBox {
		pointer-events: none;
		top: 20px;
		position: absolute;
		left: 0;
		width: 100%;
		text-align: center;
		margin: 30vh 0;

		p {
			margin: 20px 0;
			font-size: 16px;
		}
	}

	.dragArea {
		/* min-height: calc(100vh - var(--site-top-height) - 50px); */
		min-height: calc(100vh + 100px);
		background-color: #f5f5f5;
		padding: 5px 0;

		:global {
			.draggableToolItem {
				width: 100%;
				max-width: 100%;

				&:local {
					&.ghost {
						background-color: color(var(--color-primary) a(0.4)) !important;
						box-shadow: 0 3px 14px 3px color(var(--color-primary) a(0.6)), 0 10px 10px 1px color(var(--color-primary) a(0.5));
						height: 120px !important;
						padding: 20px;

						&>div {
							width: 100%;
							height: 100%;
							/* background-color: var(--color-white); */
						}

						p {
							font-size: 16px;
							line-height: 24px;
						}
					}
				}
			}

			.emptyBox {
				min-height: 350px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.viewEmpty_IconBox {
				color: color(var(--checkbox-color) a(0.7));
				font-size: 50px;
				text-align: center;
			}

			.el-image {
				vertical-align: top;
			}
		}
	}

	.ghost {
		opacity: 0.5;
	}
</style>